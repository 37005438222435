import styled from "styled-components";

export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;
