import React from "react";
import { HeaderText1, HeaderText2, HeaderText3 } from "../styles/globalStyles";

const Details = () => {
  return (
    <>
      <HeaderText1 className="heading">
        The Seed
      </HeaderText1>
      <HeaderText2 className="heading">
        META KEY
      </HeaderText2>
      <HeaderText3 >
        <p className="para2">
          GÜDPHORIA is dedicated to helping solve the climate crisis through the
          regenerative cultivation of the hemp plant.
        </p>
      </HeaderText3>
    </>
  );
};

export default Details;
