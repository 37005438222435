  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { connect } from "./redux/blockchain/blockchainActions";
  import { fetchData } from "./redux/data/dataActions";
  import {
    BodyRow,
    TheMen,
    Screen,
    StyledLink,
    Container,
    TextTitle,
    TextDescription,
    TextDescriptionMintAmount,
    StyledButton,
    StyledRoundButton,
    ResponsiveWrapper,
  } from "./styles/globalStyles";
  import Header from "./components/Header.js";
  import { SpacerMedium, SpacerSmall } from "./components/Spacer";
  import Details from "./components/Details";
  import TotalAmount from "./components/TotalAmount";
  import "bootstrap/dist/css/bootstrap.min.css";

  function App() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click mint to mint your NFT.`);
    const [mintAmount, setMintAmount] = useState(1);
    const [safariFeedback, setSafariFeedback] = useState("");
    const [CONFIG, SET_CONFIG] = useState({
      CONTRACT_ADDRESS: "",
      SCAN_LINK: "",
      NETWORK: {
        NAME: "",
        SYMBOL: "",
        ID: 0,
      },
      NFT_NAME: "",
      SYMBOL: "",
      MAX_SUPPLY: 1,
      WEI_COST: 0,
      DISPLAY_COST: 0,
      GAS_LIMIT: 0,
      MARKETPLACE: "",
      MARKETPLACE_LINK: "",
      SHOW_BACKGROUND: true,
    });

    const safariCheck = () => {
      if(!window.ethereum) setSafariFeedback("Current browser doesn't support Metamask");
    } 

    const claimNFTs = () => {
      const cost = CONFIG.WEI_COST;
      const gasLimit = CONFIG.GAS_LIMIT;
      const totalCostWei = String(cost * mintAmount);
      const totalGasLimit = String(gasLimit * mintAmount);
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .mint(mintAmount)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log("error in blockchain.smartCntract.methods", err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    };

    const decrementMintAmount = () => {
      let newMintAmount = mintAmount - 1;
      if (newMintAmount < 1) {
        newMintAmount = 1;
      }
      setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
      let newMintAmount = mintAmount + 1;
      if (newMintAmount > 5) {
        newMintAmount = 5;
      }
      setMintAmount(newMintAmount);
    };

    const getData = () => {
      if (blockchain.account !== "" && blockchain.smartContract !== null) {
        dispatch(fetchData(blockchain.account));
      }
    };

    const getConfig = async () => {
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const config = await configResponse.json();
      SET_CONFIG(config);
    };

    useEffect(() => {
      safariCheck();
    }, []); 

    useEffect(() => {
      getConfig();
    }, []);

    useEffect(() => {
      getData();
    }, [blockchain.account]);

    return (
      <Screen>
        <div className="container">
          <Header />
          <BodyRow>
            <div className="row">
              <div className="col-md-6">
                <TheMen>
                  <div className="the_men_img">
                    <img align="right" src="./card.png" alt="" />
                  </div>
                </TheMen>
              </div>
              <div className="col-md-6">
                <Container flex={1} ai={"center"}>
                  <Details />
                  <SpacerSmall />

                  <ResponsiveWrapper flex={1} style={{ width: "100%" }} test>
                    <Container
                      flex={2}
                      jc={"center"}
                      ai={"center"}
                      style={{
                        background:
                          "linear-gradient(180deg, #1F201F 0%, #000000 100%)",
                        paddingBottom: 45,
                        borderRadius: 40,
                        boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                        height: "389px",
                      }}
                    >
                      <TextTitle
                        style={{
                          textAlign: "center",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "40px",
                          lineHeight: "64px",
                          color: "var(--secondary-text)",
                          fontFamily: "Lato",
                        }}
                      >
                        {/* 0/5,555 */}
                        {/* {data.totalSupply} / {CONFIG.MAX_SUPPLY} */}
                      </TextTitle>
                      <SpacerSmall />
                      <TotalAmount mintAmount={mintAmount} />
                      {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                        <>
                          <TextTitle
                            style={{
                              textAlign: "center",
                              color: "var(--secondary)",
                            }}
                          >
                            The sale has ended.
                          </TextTitle>
                          <TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            You can still find {CONFIG.NFT_NAME} on
                          </TextDescription>
                          <SpacerSmall />
                          <StyledLink
                            target={"_blank"}
                            href={CONFIG.MARKETPLACE_LINK}
                          >
                            {CONFIG.MARKETPLACE}
                          </StyledLink>
                        </>
                      ) : (
                        <>
                          {blockchain.account === "" ||
                          blockchain.smartContract === null ? (
                            <Container ai={"center"} jc={"center"}>
                              <TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "25px",
                                  lineHeight: "36px",
                                  fontFamily: "Lato",
                                }}
                              >
                                Connect to the {CONFIG.NETWORK.NAME} network
                              </TextDescription>
                              <SpacerSmall />
                              <StyledButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(connect());
                                  getData();
                                }}
                              >
                                <span style={{ fontFamily: "Lato" }}>
                                  Connect!
                                </span>
                              </StyledButton>
                              {blockchain.errorMsg !== "" ? (
                                <>
                                  <SpacerSmall />
                                  <TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--secondary-text)",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      fontSize: "25px",
                                      lineHeight: "36px",
                                    }}
                                  >
                                    {blockchain.errorMsg}
                                  </TextDescription>
                                </>
                              ) : null}
                            </Container>
                          ) : (
                            <>
                              <Container ai={"center"} jc={"center"} fd={"row"}>
                                <span
                                  style={{
                                    paddingRight: "10px",
                                    paddingBottom: "10px",
                                    fontFamily: "Lato",
                                    textAlign: "center",
                                    color: "var(--secondary-text)",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "25px",
                                    lineHeight: "36px",
                                  }}
                                >
                                  {/* Amount */}
                                </span>
                                &nbsp;
                                <StyledRoundButton
                                  style={{ lineHeight: 0.4 }}
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decrementMintAmount();
                                  }}
                                >
                                  <span>
                                    <img
                                      src="./minus.png"
                                      alt=""
                                      className="minus_icon"
                                    />
                                  </span>
                                </StyledRoundButton>
                                <SpacerMedium />
                                <TextDescriptionMintAmount
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <span> {mintAmount}</span>
                                </TextDescriptionMintAmount>
                                <SpacerMedium />
                                <StyledRoundButton
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementMintAmount();
                                  }}
                                >
                                  <span>
                                    <img
                                      src="./plus.png"
                                      alt=""
                                      className="plus_icon"
                                    />
                                  </span>
                                </StyledRoundButton>
                              </Container>
                              <SpacerSmall />
                              <TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--secondary-text)",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "25px",
                                  lineHeight: "36px",
                                  fontFamily: "Lato",
                                }}
                              >
                                {feedback}
                                <br/>
                                <br/>

                                <p>
                                  {safariFeedback}
                                </p>
                              </TextDescription>
                              <SpacerSmall />
                              <Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledButton
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    claimNFTs();
                                    getData();
                                  }}
                                >
                                  <span>{claimingNft ? "BUSY" : "MINT!"}</span>
                                </StyledButton>
                              </Container>
                            </>
                          )}
                        </>
                      )}
                    </Container>
                  </ResponsiveWrapper>
                </Container>
              </div>
            </div>
          </BodyRow>
        </div>
      </Screen>
    );
  }

  export default App;
