import React from "react";
import { TextTitle } from "../styles/globalStyles";

const TotalAmount = (props) => {
  return (
    <TextTitle style={{ textAlign: "center", color: "var(--secondary-text)" }}>
      <span
        style={{
          textAlign: "center",
          color: "var(--secondary-text)",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "25px",
          lineHeight: "36px",
          fontFamily: "Lato",
        }}
      >
        {`Total `} : {props.mintAmount * 0.1} ETH
      </span>
    </TextTitle>
  );
};

export default TotalAmount;
