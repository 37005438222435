import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: #000000;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @font-face {
    font-family: "Belinda";
    src: local("belinda"), url(Belinda.otf) format("otf");
  }
`;

export const BodyRow = styled.div`
  .row {
    align-items: center;
    margin-top: 50px;

    @media screen and (max-width: 600px) {
      margin-top: 0px;
    }
  }
`;

export const StyledButton = styled.button`
  padding: 20px;
  font-size: 20px;
  border-radius: 29px;
  border: 3px solid #95bd79;
  background-color: transparent;
  color: var(--secondary-text);
  width: 100%;
  cursor: pointer;
`;

export const StyledRoundButton = styled.button`
  border-radius: 14px;
  border: 1px solid #95bd79;
  background-color: #000;
  padding: 22px;
  font-weight: 100;
  font-size: 32px;
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  // -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  // -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  span {
    display: flex;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  fontstyle: "normal";
  fontweight: "600";
  font-size: 25px;
  line-height: 36px;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  padding-top: 30px;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--secondary-text);
  font-size: 16px;
  line-height: 1.6;
  margin-top: 18px;
`;

export const TextDescriptionMintAmount = styled.p`
  color: #b4b4b4;
  padding: 6.5px 17px;
  border-radius: 14px;
  font-size: 20px;
  background-color: #000;
  line-height: 1.6;
`;

export const HeaderText1 = styled.p`
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  align-self: flex-start;
`;

export const HeaderText2 = styled.p`
  margin-top: 10px;
  font-size: 60px;
  background: linear-gradient(180.07deg, #95bd79, #214129);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  align-self: flex-start;
`;
export const HeaderText3 = styled.div`
  .para1 {
    font-size: 18px;
    color: #868686;
    font-family: "Lato";
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 33px;
  }
  .para1 img {
    margin-left: 10px;
    margin-right: 7px;
  }
  .para1 span {
    font-family: "Lato";
    font-size: 26px;
    font-weight: 900;
  }
  .para2 {
    font-size: 18px;
    color: #868686;
    font-family: "Lato";
    line-height: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
export const TheMen = styled.div`
  .the_men_img > img {
    width: 120%;

    @media screen and (max-width: 600px) {
      margin-top: -70px;
      margin-bottom: -50px;
    }
  }
`;
