import React from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

const MenuText = styled.div`
  .menu_text {
    display: flex;

    a {
      color: #ffffff;
      padding: 55px 30px;
      text-decoration: none;
      text-align: right;

      @media (max-width: 600px) {
        text-align: center;
        padding: 20px 30px 70px;
      }
    }

    @media (max-width: 1024px) {
      justify-content: flex-end;
    }
    @media (max-width: 600px) {
      justify-content: center;
    }
  }
`;

const LogoImage = styled.div`
  @media (max-width: 820px) {
    height: 100%;
    display: flex;
    align-items: center;
  }
  img {
    margin-top: 16px;
    height: 85px;
    @media (max-width: 820px) {
      width: 100%;
      margin-top: 0;
    }
  }
  @media (max-width: 1024px) {
    text-align: center;
  }
`;

function Header() {
  return (
    <div className="row">
      <div className="col-xl-7 col-md-4">
        <LogoImage>
          <img src="/logo.png" alt="logo" c />
        </LogoImage>
      </div>
      <div className="col-xl-5 col-md-8">
        <MenuText>
          <div className="menu_text">
            <a href="https://gudphoria.com/faq" className="ms-auto">{`FAQS`}</a>
            <a href="https://static1.squarespace.com/static/6196e8848ad0cc5c02fa4033/t/623cf35d99a7884090c8a8bd/1648226240485/TheSeedWhitePaper">{`Whitepaper`}</a>
          </div>
        </MenuText>
      </div>
    </div>
  );
}

export default Header;
